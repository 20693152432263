<template lang="pug">
  .profile
    side-bar
    title-page(firstTitle='Mis datos personales', subTitle='')
    b-overlay(:show="isLoading" rounded="lg" opacity="0.6")
      div.profile-form.container-bg
        b-form
            //- b-col(md="2")
            //-   .img-avatar
            //-   b-form-group(label='Cargar imagen:' label-for='form.file1')
                //- b-form-file(v-model='file1' :state='Boolean(file1)' placeholder='Choose a file or drop it here...' drop-placeholder='Drop file here...')
            ol
              li
                h5 Información Personal
                b-row
                  b-col(md="6")
                    b-form-group(label='Apellido paterno:' label-for='form.lastName')
                      b-form-input(v-model='form.lastName' required='' :disabled='!isEdit')
                      .error(v-if="(!$v.form.lastName.required && $v.form.lastName.$anyDirty)") Campo obligatorio
                    b-form-group(label='Apellido materno:' label-for='form.lastSecondName')
                      b-form-input(v-model='form.lastSecondName' required='' :disabled='!isEdit')
                      .error(v-if="(!$v.form.lastSecondName.required && $v.form.lastSecondName.$anyDirty)") Campo obligatorio
                    b-form-group(label='Primer Nombre:' label-for='input-2')
                      b-form-input(v-model='form.name' placeholder='' required='' :disabled='!isEdit')
                      .error(v-if="(!$v.form.name.required && $v.form.name.$anyDirty)") Campo obligatorio
                  b-col(md="6")
                    b-form-group(label='Segundo nombre:' label-for='input-2')
                      b-form-input(v-model='form.secondName' :disabled='!isEdit')
                    b-form-group(label='Tipo de documento:' label-for='form.nroDocumento')
                      b-form-input(v-model='form.nroDocumento' disabled='disabled' required='')
                    b-form-group(label='Número de documento:' label-for='form.DNI')
                      b-form-input(v-model='form.DNI' disabled='disabled' required='')
                    b-form-group(label='Fecha de nac.:' label-for='form.birthday')
                      b-form-input(v-model='form.birthday' type='date' :disabled='!isEdit' required='' )
                      .error(v-if="(!$v.form.birthday.required && $v.form.birthday.$anyDirty)") Campo obligatorio
              li
                h5 Datos de contacto
                b-col(md="6")
                  b-form-group(label='Correo electrónico:' label-for='input-1')
                    b-form-input(v-model='form.email' type='email' placeholder='' required='' :disabled='!isEdit', style='font-family:"HunterBold"')
                  b-form-group(label='Celular:' label-for='form.cel')
                    b-form-input(v-model='form.cel' required='' :disabled='!isEdit')
              li(v-if="false")
                h5.mt-5 Datos de dirección
                b-col(md="6")
                  b-form-group(label='Departamento:' label-for='form.dep')
                    b-form-input(v-model='form.dep' required='' :disabled='!isEdit')
                  b-form-group(label='Provincia:' label-for='form.prov')
                    b-form-input(v-model='form.prov' required='' :disabled='!isEdit')
                b-col(md="6")
                  b-form-group(label='Distrito:' label-for='form.dist')
                    b-form-input(v-model='form.dist' required='' :disabled='!isEdit')
                  b-form-group(label='Dirección:' label-for='form.direc')
                    b-form-input(v-model='form.direc' required='' :disabled='!isEdit')
            b-col.mt-5(md="12")
              b-button(type='reset' @click='isEdit = !isEdit' variant='default', v-if='isEdit') CANCELAR
              b-button(type='button' @click='GuardarCambios' variant='primary', v-if='isEdit') GUARDAR CAMBIOS
              b-button(type='button' @click='isEdit = !isEdit' variant='primary', v-if='!isEdit')
                | EDITAR DATOS
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import Title from '../components/title'
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  name: 'Profile',
  data () {
    return {
      file1: null,
      isLoading: false,
      isEdit: false,
      form: {
        email: '',
        name: '',
        secondName: '',
        lastName: '',
        lastSecondName: '',
        cel: '',
        birthday: '',
        DNI: '',
        direc: '',
        nroDocumento: ''
      }
    }
  },
  validations: {
    form: {
      lastName: {
        required
      },
      lastSecondName: {
        required
      },
      name: {
        required
      },
      birthday: {
        required
      }
    }
  },
  components: {
    'title-page': Title
  },
  computed: {
    ...mapState(['user']),
    editIcon () {
      return faEdit
    }
  },
  watch: {
    user (val) {
      if (val !== null) {
        this.completeData()
      }
    }
  },
  mounted () {
    this.completeData()
  },
  methods: {
    ...mapMutations(['updateLocalUser']),
    ...mapActions(['UpdateUser']),
    completeData () {
      const user = this.user
      if (user != null) {
        this.form.lastName = user.APELLIDO_PATERNO
        this.form.lastSecondName = user.APELLIDO_MATERNO
        this.form.name = user.PRIMER_NOMBRE
        this.form.secondName = user.SEGUNDO_NOMBRE
        this.form.cel = user.TELEFONO
        this.form.email = user.CORREO
        this.form.birthday = this.$moment(user.FECHA_NACIMIENTO, "DD/MM/YYYY").format("YYYY-MM-DD")
        this.form.DNI = user.ID_CLIENTE
        this.form.nroDocumento = user.TIPO_IDENTIFICACION.split("|")[1]
      }
    },
    async GuardarCambios () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        console.log('Validar formulario')
      } else {
        this.isLoading = true
        const datos = {
          APELLIDO_PATERNO: this.form.lastName,
          APELLIDO_MATERNO: this.form.lastSecondName,
          PRIMER_NOMBRE: this.form.name,
          SEGUNDO_NOMBRE: this.form.secondName,
          FECHA_NACIMIENTO: this.$moment(this.form.birthday).format("DD/MM/YYYY"),
          CORREO: this.form.email,
          TELEFONO: this.form.cel.toString(),
          TIPO_IDENTIFICACION: this.user.TIPO_IDENTIFICACION
        }
        try {
          const res = await this.UpdateUser(datos)
          if(res.data.status == "success") {
            this.updateLocalUser(datos)
            this.$bvModal.msgBoxOk('Datos actualizados.', {
                title: 'Hunter',
                centered: true
              })
          }
        } catch {
          console.log('error')
        } finally {
          this.isLoading = false
          this.isEdit = false
        }
      }
    }
  }
}
</script>

<style lang="scss">
  @import '../assets/scss/Profile/style.scss';
</style>
